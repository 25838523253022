import React from "react";

import "./Navbar.scss";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/userRedux";

export const Navbar = () => {
  const dispatch = useDispatch(logout());

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <div className="navbar">
      <div className="left">
        <h1 className="title-web">Admin</h1>
      </div>
      <div className="right">
        <div className="user-icon"></div>
        <button onClick={handleLogout}>Logout</button>
      </div>
    </div>
  );
};
