import React, { useEffect, useState } from "react";
import "./Settings.scss";
import { Box, Button, TextField } from "@mui/material";
import { userRequest } from "../../requestMethods";
import LoadingButton from '@mui/lab/LoadingButton';

export const Settings = () => {
  const [settings, setSettings] = useState([]);

  useEffect(() => {
    const getSettings = async () => {
      const res = await userRequest.get("/settings");
      setSettings(res.data.settings);
    };
    getSettings();
  }, []); // Add an empty dependency array to run the effect only once

  const handleUpdate = async (index) => {
    try {
      const updatedSetting = settings[index];
      // Make a PUT request to update the setting
      const res = await userRequest.put(
        `/setting/${updatedSetting?.id}`,
        updatedSetting
      );
      // Assuming the response contains the updated setting
      const updatedSettingsList = [...settings];
      updatedSettingsList[index] = res.data.updatedSetting;
      setSettings(updatedSettingsList);
    } catch (error) {
      console.error("Error updating setting:", error);
    }
  };

  const handleDelete = async (index) => {
    try {
      const settingToDelete = settings[index];
      // Make a DELETE request to delete the setting
      await userRequest.delete(`/setting/${settingToDelete?.id}`);
      const updatedSettingsList = settings.filter((_, i) => i !== index);
      setSettings(updatedSettingsList);
    } catch (error) {
      console.error("Error deleting setting:", error);
    }
  };

  return (
    <div className="settings">
      <h4 className="h4-title">Settings</h4>
      {settings.map((setting, index) => (
        <Box
          key={index}
          className="main-setting"
          sx={{
            display: "flex",
            gap: "1rem",
            marginBottom: "1.5rem"
          }}
        >
          <TextField
            label="Key"
            defaultValue={setting?.key}
            onChange={(e) => {
              const newSettings = [...settings]; 
              newSettings[index].key = e.target.value; 
              setSettings(newSettings); 
            }}
          />
          <TextField
            label="Value"
            defaultValue={setting?.value}
            onChange={(e) => {
              const newSettings = [...settings]; 
              newSettings[index].value = e.target.value; 
              setSettings(newSettings); 
            }}
            fullWidth
          />
          <TextField
            label="Description"
            defaultValue={setting?.description}
            onChange={(e) => {
              const newSettings = [...settings]; 
              newSettings[index].description = e.target.value; 
              setSettings(newSettings); 
            }}
          />
        
          <LoadingButton
            color="success"
            onClick={() => handleUpdate(index)}
            loading
            loadingPosition="start"
            variant="contained"
            sx={{
              minWidth: 150
            }}
          >
            Update
          </LoadingButton>
          <Button
            variant="contained"
            color="error"
            onClick={() => handleDelete(index)}
            sx={{
              minWidth: 150
            }}
          >
            Delete
          </Button>
        </Box>
      ))}
    </div>
  );
};
