import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";

import { Navbar } from "./components/Navbar/Navbar";
import { Sidebar } from "./components/Sidebar/Sidebar";

import { Home } from "./pages/Home/Home";

import { Reports } from "./pages/Reports/Reports";

import { MailList } from "./pages/MailList/MailList";
import { Register } from "./pages/Register/Register";
import { Login } from "./pages/Login/Login";
import { Teams } from "./pages/Teams/Teams";

import { Settings } from "./pages/Settings/Settings";

import "./default.scss";
import { useSelector } from "react-redux";

function App() {
  const isLogin = useSelector((state) => state?.user?.currentUser?.user);

  console.log("isLogin ", isLogin);

  return (
    <Router>
      {isLogin ? (
        <div className="wrapper">
          <Navbar />
          <div className="main-container">
            <Sidebar />
            <div className="main-content">
              <div className="box-content">
                <Routes>
                  <Route path="/" exact element={<Home />} />
                  <Route path="/login" exact element={<Navigate to="/" />} />
                  <Route path="/reports" element={<Reports />} />
                  <Route path="/mails" element={<MailList />} />
                  <Route path="/teams" element={<Teams />} />
                  <Route path="/settings" element={<Settings />} />
                </Routes>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      )}
    </Router>
  );
}

export default App;
