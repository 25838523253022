import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { publicRequest } from "../../requestMethods";
import "./Register.scss";
import Swal from "sweetalert2";

export const Register = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const showSwal = (status, message) => {
    Swal.fire({
      title: status === 1 ? "Error!" : "Success",
      text: message,
      icon: status === 1 ? "error" : "success",
      confirmButtonText: "OK"
    }).then((result) => {
      if (result.isConfirmed && status === 0) {
        navigate("/login");
      }
    });
  };

  const handleSubmit = async () => {
    if (username && email && password) {
      const res = await publicRequest.post("/auth/register", {
        username,
        email,
        password
      });
      showSwal(res.data.status, res.data.message);
    } else {
      showSwal(1, "Please enter username and password!");
    }
  };

  return (
    <div className="register">
      <div className="register-container">
        <div className="title">Register Form</div>
        <div className="box-content">
          <div className="field">
            <input
              type="text"
              name="username"
              onChange={(e) => setUsername(e.target.value)}
              value={username}
            />
            <label>User Name</label>
          </div>
          <div className="field">
            <input
              type="email"
              name="email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
            <label>Email Address</label>
          </div>
          <div className="field">
            <input
              type="password"
              name="password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
            <label>Password</label>
          </div>

          <div className="field">
            <input type="submit" onClick={handleSubmit} defaultValue="Login" />
          </div>
          <div className="signup-link">
            <Link to="/login">
              Not a member? <span href="#">Signup now</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
