import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { MaterialReactTable } from "material-react-table";
import { Box, Button, FormLabel, IconButton, Tooltip } from "@mui/material";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "./Reports.scss";
import utc from "dayjs/plugin/utc"; // Plugin hỗ trợ múi giờ UTC
dayjs.extend(utc);

export const Reports = () => {
  const now = dayjs();
  const converDateTime = (dateTime) => {
    return dateTime.utc().format("YYYY-MM-DDTHH:mm:ss[Z]");
  };
  const [startDate, setStartDate] = useState(now.add(-30, "day"));
  const [endDate, setEndDate] = useState(now);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const url = `https://commissions.api.cj.com/query`;

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const response = await axios.post(
          url,
          {
            query: `query {
              publisherCommissions(
                forPublishers: ["6839845"],
                sincePostingDate:"${converDateTime(startDate)}",
                beforePostingDate: "${converDateTime(endDate)}"
              ) {
                count
                payloadComplete
                records {
                  actionTrackerName
                  websiteName
                  advertiserName
                  postingDate
                  pubCommissionAmountUsd
                  shopperId
                  items {
                    quantity
                    perItemSaleAmountPubCurrency
                    totalCommissionPubCurrency
                  }
                }
              }
            }`
          },
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_REPORT_KEY}`
            }
          }
        );

        setData(response.data.data?.publisherCommissions?.records ?? []);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [startDate, endDate]);

  const columns = useMemo(
    () => [
      // {
      //   accessorKey: 'actionTrackerName', //access nested data with dot notation
      //   header: 'Action Name',
      //   minSize: 100,
      // },
      {
        accessorKey: "advertiserName",
        header: "Advertiser Name",
        minSize: 100,
        muiTableHeadCellProps: {
          align: "center"
        },
        muiTableBodyCellProps: {
          align: "center"
        }
      },
      {
        accessorKey: "pubCommissionAmountUsd", //normal accessorKey
        header: "Publisher Commission (USD)",
        minSize: 300,
        muiTableHeadCellProps: {
          align: "center"
        },
        muiTableBodyCellProps: {
          align: "center"
        },
        Cell: ({ cell }) => {
          return <div>${cell.getValue()}</div>;
        }
      },
      {
        accessorKey: "websiteName",
        header: "Website Name",
        minSize: 100,
        muiTableHeadCellProps: {
          align: "center"
        },
        muiTableBodyCellProps: {
          align: "center"
        }
      },
      {
        accessorKey: "shopperId", //normal accessorKey
        header: "CID",
        minSize: 100,
        muiTableHeadCellProps: {
          align: "center"
        },
        muiTableBodyCellProps: {
          align: "center"
        }
      }
    ],
    []
  );

  return (
    <div className="reports">
      <h4 className="h4-title">Reporting</h4>

      <MaterialReactTable
        columns={columns}
        data={data}
        enableRowNumbers
        state={{ isLoading }}
        //add custom action buttons to top-left of top toolbar
        renderTopToolbarCustomActions={({ table }) => (
          <Box
            className="box-quick-search"
            sx={{ display: "flex", gap: "1rem" }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Start Date"
                defaultValue={startDate}
                disableFuture
                onChange={(e) => {
                  setStartDate(e);
                  setEndDate(e.add(+30, "day"));
                }}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="End Date"
                defaultValue={endDate}
                disableFuture
                onChange={(e) => setEndDate(e)}
              />
            </LocalizationProvider>
            {/* <Button
              color="success"
              onClick={() => {
                alert("Create New Account");
              }}
              variant="contained"
            >
              Run Report
            </Button> */}
          </Box>
        )}
      />
    </div>
  );
};
