import React, { useEffect, useState } from "react";
import axios from "axios";

import { MaterialReactTable } from "material-react-table";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { ExportToCsv } from "export-to-csv";
import "./MailList.scss";
// eslint-disable-next-line
export const MailList = () => {
  // DATA AND FETCHING STATE
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [categories, setCategories] = useState([]);
  // const [cateColor, setCateColor] = useState([]);

  // TABLE STATE
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10
  });

  // GET CATEGORIES
  useEffect(() => {
    axios
      .get(
        process.env.NODE_ENV === "production"
          ? `https://api.goodchoiceyourbest.com/category`
          : `http://localhost:5000/category`
      )
      .then((res) => {
        setCategories(res.data.data);
      })
      .catch((err) => console.log(err));
  }, []);
  // GET MAIL
  useEffect(() => {
    const fetchData = async () => {
      if (!data.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }

      axios
        .get(
          process.env.NODE_ENV === "production"
            ? `https://api.goodchoiceyourbest.com/contact`
            : `http://localhost:5000/contact`,
          {
            params: {
              start: pagination.pageIndex * pagination.pageSize,
              size: pagination.pageSize
            }
          }
        )
        .then((res) => {
          setData(res.data.data);
          setRowCount(res.data.meta.totalRecords);

          setIsError(false);
          setIsLoading(false);
          setIsRefetching(false);
        })
        .catch((err) => {
          setIsError(true);
          setIsLoading(true);
          setIsRefetching(true);
          console.error(err);
          return;
        });
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting
  ]);

  const columns = [
    {
      accessorKey: "id",
      header: "ID",
      size: 40
    },
    {
      accessorKey: "mail",
      header: "Mail",
      size: 120
    },
    {
      accessorKey: "category_id",
      header: "category",
      size: 120,
      Cell: ({ cell }) => {
        let cat_id = parseInt(cell.getValue());
        const cat = categories.find((cat) => cat.id === cat_id);
        return (
          <span
            className={
              "cat-name " +
              (cat_id === 1 ? "cat-sty-1" : cat_id === 2 ? "cat-sty-2" : "")
            }
          >
            {cat.name}
          </span>
        );
      }
    }
  ];

  const csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.header)
  };

  const csvExporter = new ExportToCsv(csvOptions);

  const handleExportRows = (rows) => {
    const exportData = rows.map((row) => row.original);
    const exportDataConvert = exportData.map((item) => {
      const category = categories.find((cat) => cat.id === item.category_id);
      return {
        ...item,
        category_id: category ? category.name : "Unknown Category"
      };
    });

    csvExporter.generateCsv(exportDataConvert);
  };

  const handleExportData = () => {
    const exportData = data.map((item) => {
      const category = categories.find((cat) => cat.id === item.category_id);
      return {
        ...item,
        category_id: category ? category.name : "Unknown Category"
      };
    });
    csvExporter.generateCsv(exportData);
  };

  console.log("data ", data);
  console.log("rowCount ", rowCount);

  return (
    <div className="mail-list">
      <MaterialReactTable
        columns={columns}
        data={data}
        enableRowSelection
        getRowId={(row) => row.phoneNumber}
        initialState={{ showColumnFilters: false }}
        manualFiltering
        manualPagination
        manualSorting
        muiToolbarAlertBannerProps={
          isError
            ? {
                color: "error",
                children: "Error loading data"
              }
            : undefined
        }
        onColumnFiltersChange={setColumnFilters}
        onGlobalFilterChange={setGlobalFilter}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
        rowCount={rowCount}
        state={{
          columnFilters,
          globalFilter,
          isLoading,
          pagination,
          showAlertBanner: isError,
          showProgressBars: isRefetching,
          sorting
        }}
        renderTopToolbarCustomActions={({ table }) => (
          <Box
            sx={{ display: "flex", gap: "1rem", p: "0.5rem", flexWrap: "wrap" }}
          >
            <Button
              color="primary"
              //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
              onClick={handleExportData}
              startIcon={<FileDownloadIcon />}
              variant="contained"
            >
              Export All Data
            </Button>
            <Button
              disabled={
                !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
              }
              //only export selected rows
              onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
              startIcon={<FileDownloadIcon />}
              variant="contained"
            >
              Export Selected Rows
            </Button>
          </Box>
        )}
      />
    </div>
  );
};
