import React, { useState } from "react";
import "./Sidebar.scss";
// import { Link } from "react-router-dom";
// import { FaBars } from "react-icons/fa";
// import { AiOutlineClose } from "react-icons/ai";
import { SidebarData } from "./SidebarData";
import SubMenu from "../SubMenu/SubMenu";
export const Sidebar = () => {
  const [sidebar, setSidebar] = useState(true);

  const showSidebar = () => setSidebar(!sidebar);

  return (
    <div className="sidebar">
      {/* <div className="nav">
        <Link to="#" className="nav-icon">
          <FaBars onClick={showSidebar} />
        </Link>
      </div> */}
      <nav className="sidebar-nav">
        <div className={`sidebar-wrap ${sidebar ? "active" : ""}`}>
          {/* <Link to="#" className="nav-icon">
            <AiOutlineClose onClick={showSidebar} />
          </Link> */}
          {SidebarData.map((item, index) => {
            return <SubMenu item={item} key={index} />;
          })}
        </div>
      </nav>
    </div>
  );
};
