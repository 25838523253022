import { loginStart, loginSuccess, loginFalse } from "./userRedux";
import { publicRequest } from "../requestMethods";

// LOGIN
export const login = async (dispath, user) => {
  dispath(loginStart());

  try {
    const res = await publicRequest.post("/login", user);
    dispath(loginSuccess(res.data));
    return res.data;
  } catch (error) {
    dispath(loginFalse());
    return { status: 1, message: "server error!" };
  }
};

// REGISTER
export const register = async (dispath, user) => {};
