import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./SubMenu.scss";

export const SubMenu = ({ item }) => {
  const [subnav, setSubnav] = useState(false);
  const showSubnav = () => setSubnav(!subnav);
	const location = useLocation();
	const slug = location.pathname.split("/")[1];
  const link = item.path.split("/")[1]

  return (
    <>
      <Link
        to={item.path}
        className={`SidebarLink ${link === slug ? 'active' : ''}`}
        onClick={item.subNav && showSubnav}
      >
        <div>
          {item.icon}
          <span className='SidebarLabel'>{item.title}</span>
        </div>
        <div>
          {item.subNav && subnav
            ? item.iconOpened
            : item.subNav
            ? item.iconClosed
            : null}
        </div>
      </Link>
      {subnav &&
        item.subNav.map((item, index) => {
          const path = item.path
          return (
            <Link to={path} className='DropdownLink' key={index}>
              {item.icon}
              <span className="SidebarLabel">{item.title}</span>
            </Link>
          );
        })}
    </>
  );
};

export default SubMenu;
