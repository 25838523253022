import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { login } from "../../redux/apiCall";

import "./Login.scss";
import { Link } from "react-router-dom";

export const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();

  const handleLogin = async () => {
    if (email && password) {
      try {
        const res = await login(dispatch, { email, password });
        console.log(res);
        res.status !== 200 && setMessage(res.message);
      } catch (error) { }
    } else {
      setMessage("Please enter email and password!");
    }
  };

  useEffect(() => {
    const addSpanDom = () => {
      const section = document.querySelector("section");
      for (let i = 1; i < 150; i++) {
        const spanEl = document.createElement("span");
        section.appendChild(spanEl);
      }
    };

    addSpanDom();
  }, []);

  return (
    <div className="login">
      <section>
        <div className="signin">
          <div className="content">
            <h2>Sign In</h2>
            <div className="form">
              <div className="inputBox">
                <input
                  type="text"
                  required=""
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setMessage("");
                  }}
                />{" "}
                <i>Email</i>
              </div>
              <div className="inputBox">
                <input
                  type="password"
                  required=""
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setMessage("");
                  }}
                />{" "}
                <i>Password</i>
              </div>
              <div className="message">{message}</div>
              <div className="links">
                <Link to='/forgot-password'> Forgot Password</Link> <Link to='/signup'> Signup</Link>
              </div>
              <div className="inputBox">
                <input
                  type="submit"
                  defaultValue="Login"
                  onClick={handleLogin}
                />
              </div>
            </div>
          </div>
        </div>
      </section>{" "}
    </div>
  );
};
